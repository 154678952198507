import React from 'react';
import Home from './pages/Home'; // Import your Home component
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>ENV: { process.env.REACT_APP_ENV }</h1>
      <h2>LOGIN_BASE_URL: { process.env.REACT_APP_LOGIN_BASE_URL } </h2>
      <Home />
    </div>
  );
}

export default App;
