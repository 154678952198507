import React from 'react';
import Cookies from 'universal-cookie'; // Import universal-cookie package
import './Home.css'; // Import any CSS file for styling if needed

const cookies = new Cookies();

function Home() {
  const handleSubscribe = () => {
    // Code for subscription functionality
    console.log('Subscription functionality goes here');
  };

  const handleLogin = () => {
    // Code for login functionality
    // window.location.href = 'https://login.corretei.com/auth';
    window.location.href = process.env.REACT_APP_LOGIN_BASE_URL + '/auth';
  };

  const handleLogout = () => {
    // Code for logout functionality
    // window.location.href = 'https://login.corretei.com/logout';
    window.location.href = process.env.REACT_APP_LOGIN_BASE_URL + '/logout';
  };

  const accessToken = cookies.get('access_token');
  const refreshToken = cookies.get('refresh_token');
  const userToken = cookies.get('user_token');

  // Log cookies to console
  console.log('Access Token:', accessToken);
  console.log('Refresh Token:', refreshToken);
  console.log('User Token:', userToken);

  // If all tokens are present, render them along with the logout button
  if (accessToken && refreshToken && userToken) {
    return (
      <div className="home-container">
        <div className="token-container">
          <p>Access Token:</p>
          <div>{accessToken}</div>
        </div>
        <div className="token-container">
          <p>Refresh Token:</p>
          <div>{refreshToken}</div>
        </div>
        <div className="token-container">
          <p>User Token:</p>
          <div>{userToken}</div>
        </div>
        <button id="button-logout" className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  // Otherwise, render the login and subscribe buttons
  return (
    <div className="home-container">
      <button id="button-subscribe" className="sign-button" onClick={handleSubscribe}>Assinar</button>
      <button id="button-login" className="login-button" onClick={handleLogin}>Login</button>
    </div>
  );
}

export default Home;
